<template>
  <div class="scrollbar-wrapper" :class="{ collapse }">
    <div class="logo-icon" :class="{ small: collapse }"></div>
    <div class="menu-wrapper">
      <el-menu
        ref="elMenu"
        mode="vertical"
        background-color="#001529"
        text-color="#A3A7AB"
        active-text-color="#A3A7AB"
        :collapse="collapse"
        :default-active="this.$route.meta.menuId"
      >
        <!-- <div class="menu-wrapper"> -->
        <el-submenu index="3" key="App">
          <template slot="title">
            <i class="iconfontmenu iconyingyongshezhi"></i>
            <span slot="title" style="font-size: 14px">应用与服务</span>
          </template>
          <template v-for="(item, index) in subSystem">
            <router-link
              v-if="item.app !== 'small' || item.app !== 'cp' || (item.app.toLowerCase() != 'ccp')"
              :key="item.id"
              :to="{ path: '/subSystem/' + item.id }"
              @click.native="toSubSystem(index, item)"
            >
              <el-menu-item
                v-if="item.app !== 'cm'"
                :index="`3${item.id}`"
                :class="subIndex == index ? 'is-active' : ''"
              >
                <span slot="title">{{ item.app_name }}</span>
              </el-menu-item>
            </router-link>
            <div v-else :key="item.id" @click="toSubSystem(index, item)">
              <el-menu-item
                v-if="item.app !== 'cm'"
                :index="`3${item.id}`"
                :class="subIndex == index ? 'is-active' : ''"
              >
                <span slot="title">{{ item.app_name }}</span>
              </el-menu-item>
            </div>
          </template>
        </el-submenu>

        <el-submenu
          index="2"
          key="系统设置"
          v-if="
            power.groupshow ||
              power.managershow ||
              power.roleshow ||
              power.childnetshow ||
              power.logshow ||
              power.customershow ||
              userInfo.is_admin == 1
          "
        >
          <template slot="title">
            <i class="iconfontmenu iconqitafuwu"></i>
            <span slot="title">其他服务</span>
          </template>
          <template>
            <!--
             <router-link :to="'/sysSetting/group'" key="组织管理">
               <el-menu-item index="25" v-if="power.groupshow">
                 <span slot="title">组织管理</span>
               </el-menu-item>
             </router-link>
             <router-link :to="'/sysSetting/auth'" key="权限管理">
               <el-menu-item index="27" v-if="userInfo.is_admin == 1">
                 <span slot="title">权限管理</span>
               </el-menu-item>
             </router-link>
             <router-link :to="'/sysSetting/manager'" key="管理员管理">
               <el-menu-item index="22" v-if="power.managershow">
                 <span slot="title">管理员管理</span>
               </el-menu-item>
             </router-link>
             <router-link :to="'/sysSetting/role'" key="角色管理">
               <el-menu-item index="21" v-if="power.roleshow">
                 <span slot="title">角色管理</span>
               </el-menu-item>
             </router-link>
             <router-link :to="'/sysSetting/login-record'" key="用户登录记录">
               <el-menu-item index="26" v-if="power.logshow">
                 <span slot="title">登录日志</span>
               </el-menu-item>
             </router-link>
             <router-link :to="'/sysSetting/wechat'" key="微信登录">
               <el-menu-item index="201" v-if="userInfo.is_admin == 1">
                 <span slot="title">微信登录</span>
               </el-menu-item>
             </router-link>-->
            <!-- <router-link :to="'/sysSetting/config'" key="应用系统配置管理">
               <el-menu-item index="23" v-if="power.childnetshow">
                 <span slot="title">应用系统配置管理</span>
               </el-menu-item>
             </router-link> -->
            <!-- <router-link :to="'/sysSetting/p-location'" key="行政区划设置">
               <el-menu-item index="29" v-if="userInfo.is_admin == 1">
                 <span slot="title">行政区划设置</span>
               </el-menu-item>
             </router-link> -->

            <router-link :to="'/sysSetting/sensitive'" key="敏感词审核设置">
              <!-- is_admin是？？ v-if="userInfo.is_admin == 1"-->
              <el-menu-item index="202">
                <span slot="title">敏感词审核</span>
              </el-menu-item>
            </router-link>
            <!-- 消息推送-->
            <router-link :to="{ path: '/push-edit' }">
              <el-menu-item index="990">
                <span slot="title">消息推送</span>
              </el-menu-item>
            </router-link>
            <!--            <el-submenu index="5" key="消息推送">-->
            <!--              <template slot="title">-->
            <!--                <i class="el-icon-message"></i>-->
            <!--                <span slot="title">消息推送</span>-->
            <!--              </template>-->
            <!--              <template>-->
            <!--                <router-link :to="{ path: '/push-edit' }">-->
            <!--                  <el-menu-item index="51">-->
            <!--                    <span slot="title">推送通知</span>-->
            <!--                  </el-menu-item>-->
            <!--                </router-link>-->
            <!--                <router-link :to="{ path: '/push-history' }">-->
            <!--                  <el-menu-item index="52">-->
            <!--                    <span slot="title">推送历史</span>-->
            <!--                  </el-menu-item>-->
            <!--                </router-link>-->
            <!--                <router-link :to="{ path: '/push-setting' }">-->
            <!--                  <el-menu-item index="53" v-if="power.pushSettingShow">-->
            <!--                    <span slot="title">推送配置</span>-->
            <!--                  </el-menu-item>-->
            <!--                </router-link>-->
            <!--              </template>-->
            <!--            </el-submenu>-->
          </template>
        </el-submenu>

        <el-submenu
          index="1"
          key="index"
          v-if="
            power.accountshow || power.accountbillshow || power.accountordershow || power.serviceordershow
          "
        >
          <template slot="title">
            <i class="iconfontmenu iconcaiwuzhongxin"></i>
            <span slot="title">财务中心</span>
          </template>
          <template>
            <router-link :to="'/account-bill'">
              <el-menu-item index="12" v-if="power.accountbillshow">
                <span slot="title">资源用量</span>
              </el-menu-item>
            </router-link>
<!--            <router-link :to="'/account-bill-old'">-->
<!--              <el-menu-item index="60" v-if="power.accountbillshow">-->
<!--                <span slot="title">资源用量(旧测)</span>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
<!--            <router-link :to="'/account-order'">-->
<!--              <el-menu-item index="68" v-if="power.accountbillshow">-->
<!--                <span slot="title">机构订单管理(旧测)</span>-->
<!--              </el-menu-item>-->
<!--            </router-link>-->
            <!--<router-link :to="'/account-order'">
              <el-menu-item index="13" v-if="power.accountordershow">
                <span slot="title">机构订单管理</span>
              </el-menu-item>
            </router-link>-->
            <router-link :to="'/account-bill-manage'">
              <el-menu-item index="14" v-if="power.accountordershow">
                <span slot="title">账单管理</span>
              </el-menu-item>
            </router-link>
            <router-link :to="'/extract-manage'">
              <el-menu-item index="16" v-if="power.accountordershow">
                <span slot="title">提现管理</span>
              </el-menu-item>
            </router-link>
            <!--<router-link :to="'/account-fund-manage'">
                                        <el-menu-item index="15" v-if="power.accountordershow">
                                            <span slot="title">支付宝资金对账</span>
                                        </el-menu-item>
            </router-link>-->
          </template>
        </el-submenu>
        <el-menu-item index="out" v-if="ywData" @click="toYw">
          <i class="iconfontmenu iconjishuyunwei"></i>
          <span slot="title" style="font-size: 14px">技术云维</span>
        </el-menu-item>
        <el-menu-item
          index="out-integral"
          v-if="integralDomain && menu.creditShow"
          @click="toIntegral"
        >
          <i class="iconfont iconjifenguanli"></i>
          <span slot="title" style="font-size: 14px">积分管理</span>
        </el-menu-item>
        <!-- <router-link v-else :to="{ path: '/account' }">
          <el-menu-item index="11">
            <span slot="title">机构管理</span>
          </el-menu-item>
        </router-link> -->
        <el-submenu
          index="38"
          key="account">
          <template slot="title">
            <i class="iconfontmenu iconjigouguanli"></i>
            <span slot="title">机构</span>
          </template>
          <template>
            <router-link :to="'/account'">
              <el-menu-item index="11" v-if="power.accountshow">
                <span slot="title" style="font-size: 14px">机构管理</span>
              </el-menu-item>
            </router-link>
            <router-link :to="'/org-account'">
              <el-menu-item index="24">
                <span slot="title" style="font-size: 14px">资源用量</span>
              </el-menu-item>
            </router-link>
            <router-link :to="'/account-order'">
              <el-menu-item index="13" v-if="power.accountordershow">
                <span slot="title">机构订单管理</span>
              </el-menu-item>
            </router-link>
            <router-link :to="'/service-order'">
              <el-menu-item index="54" v-if="power.serviceordershow">
                <span slot="title">增值服务</span>
              </el-menu-item>
            </router-link>
          </template>
        </el-submenu>
        <router-link :to="'/practice'">
          <el-menu-item index="22" v-if="menu.practiceShow">
            <i class="el-icon-s-promotion"></i>
            <span slot="title" style="font-size: 14px">新时代文明实践</span>
          </el-menu-item>
        </router-link>
        <router-link :to="'/sysSetting/customer'" key="C端用户管理">
          <el-menu-item index="28" v-if="power.customershow">
            <i class="iconfontmenu iconyonghuzhongxin"></i>
            <span slot="title" style="font-size: 14px">用户中心管理</span>
          </el-menu-item>
        </router-link>

        <!-- <el-submenu index="3" key="App">
           <template slot="title">
             <i class="el-icon-files"></i>
             <span slot="title">应用</span>
           </template>
           <template>
             <router-link
                 v-for="(item, index) in subSystem"
                 :key="item.id"
                 :to="{ path: '/subSystem/' + item.id }"
                 @click.native="toSubSystem(index)"
             >
               <el-menu-item
                   :index="`3${item.id}`"
                   :class="subIndex == index ? 'is-active' : ''"
               >
                 <span slot="title">{{ item.app_name }}</span>
               </el-menu-item>
             </router-link>
           </template>
         </el-submenu>-->

        <!--<router-link :to="{ path: '/message' }">
        <el-menu-item index="41">
           <i class="el-icon-chat-dot-round"></i>
           <span slot="title" style="font-size: 14px">消息管理</span>
         </el-menu-item>
        </router-link>-->

        <!-- <el-submenu index="5" key="消息推送">
          <template slot="title">
            <i class="el-icon-message"></i>
            <span slot="title">消息推送</span>
          </template>
          <template>
            <router-link :to="{ path: '/push-edit' }">
              <el-menu-item index="51">
                <span slot="title">推送通知</span>
              </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/push-history' }">
              <el-menu-item index="52">
                <span slot="title">推送历史</span>
              </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/push-setting' }">
              <el-menu-item index="53" v-if="power.pushSettingShow">
                <span slot="title">推送配置</span>
              </el-menu-item>
            </router-link>
          </template>
        </el-submenu>-->
        <el-submenu
          index="4"
          v-if="
            power.SportTenatShow ||
              power.SportOrderShow ||
              power.SportTemplateShow ||
              power.SportHotShow
          "
          key="S端"
        >
          <template slot="title">
            <i
              class="el-icon-monitor"
              style="font-size: 16px; margin-right: 16px"
            ></i>
            <span slot="title">S端</span>
          </template>
          <template>
            <router-link
              :to="`/sportSystem?model=1&t=${MRADOM}`"
              @click.native="gotoSport"
              key="商户管理"
            >
              <el-menu-item index="33" v-if="power.SportTenatShow">
                <span slot="title">商户管理</span>
              </el-menu-item>
            </router-link>
            <router-link
              :to="`/sportSystem?model=2&t=${MRADOM}`"
              @click.native="gotoSport"
              key="权限管理"
            >
              <el-menu-item index="32" v-if="power.SportOrderShow">
                <span slot="title">订单管理</span>
              </el-menu-item>
            </router-link>
            <router-link
              :to="`/sportSystem?model=3&t=${MRADOM}`"
              @click.native="gotoSport"
              key="应用配置模板"
            >
              <el-menu-item index="31" v-if="power.SportTemplateShow">
                <span slot="title">应用配置模板管理</span>
              </el-menu-item>
            </router-link>
            <router-link
              :to="`/sportSystem?model=5&t=${MRADOM}`"
              @click.native="gotoSport"
              key="热点池"
            >
              <el-menu-item index="39" v-if="power.SportHotShow">
                <span slot="title">热点池</span>
              </el-menu-item>
            </router-link>
            <!-- <router-link
              :to="`/sportSystem?model=6&t=${MRADOM}`"
              @click.native="gotoSport"
              key="技术云维"
            > -->
              <el-menu-item index="310" v-if="power.SportHotShow" @click="toTecMag">
                <span slot="title">技术云维</span>
              </el-menu-item>
            <!-- </router-link> -->
          </template>
        </el-submenu>
        <!-- </div> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState} from "vuex";
import SidebarItem from "./SidebarItem";
import { getNetwork } from "@/api/networkConfig";
import { getModuleByParctice } from "@/api/practice";
import { getPersonalInfo } from "@/api/user";
import { getSdomian } from "@/api/common";
import { cookie } from "@/utils/cookie.js";

export default {
  components: { SidebarItem },
  props: {
    isAdmin: {
      type: Number,
      default: 0,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      subSystem: [],
      subIndex: -1,
      power: {
        accountshow: false,
        accountbillshow: false,
        accountordershow: false,
        childnetshow: false,
        logshow: false,
        managershow: false,
        groupshow: false,
        roleshow: false,
        authshow: false,
        customershow: false,
        SportTenatShow: false,
        SportOrderShow: false,
        SportTemplateShow: false,
        SportHotShow: false,
        pushSettingShow: false,
        serviceordershow: false,
        MRADOM: Math.random(),
      },
      menu: {
        practiceShow: false,
        creditShow: false,
      },
      domain: window.location.host,
      // 云维数据
      ywData: null,
      // 积分管理入口
      integralDomain: "",
      menu: {
        creditShow: false,
      },
    };
  },
  computed: {
    ...mapGetters(["permission_routers", "userInfo"]),
    ...mapState("base", ["domias"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  created() {
    this._getModuleByParctice();

    this.MRADOM = Math.random();
    const canSeeAccountshow = [
      "p_sys_client_all",
      "p_sys_client_modify",
      "p_sys_client_view",
    ];
    const canSeeAccountbillshow = ["p_emc_fee_all"];
    const canSeeAccountordershow = [
      "p_emc_clientorder_all",
      "p_emc_clientorder_view",
    ];
    const canSeeChildnetshow = ["p_sys_subsystem_all"];
    const canSeeLogshow = ["p_sys_log_all"];
    const canSeeManagershow = [
      "p_sys_admin_all",
      "p_sys_admin_modify",
      "p_sys_admin_view",
    ];
    const canSeeGroupshow = [
      "p_sys_group_all",
      "p_sys_group_modify",
      "p_sys_group_view",
    ];
    const canSeeRoleshow = [
      "p_sys_role_all",
      "p_sys_role_modify",
      "p_sys_role_view",
    ];
    const canCustomershow = ["p_sys_user_view", "p_sys_user_all"];
    const SportTenatshow = [
      "p_mp_platform_all",
      "p_mp_platform_view",
      "p_mp_platform_edit",
      "p_mp_platform_down",
      "p_mp_platform_secret",
    ];
    const SportOrdershow = [
      "p_mp_order_all",
      "p_mp_order_view",
      "p_mp_order_edit",
    ];
    const Sporttemplateshow = [
      "p_mp_app_all",
      "p_mp_app_view",
      "p_mp_app_edit",
    ];
    // 热点池
    const SportHotShow = ["p_mp_app_all", "p_mp_app_view", "p_mp_app_edit"];
    const pushSettingShow = ["p_cms_msgpush_config_all"];

    const canSeeServiceordershow = [
      "p_emc_clientorder_all",
      "p_emc_clientorder_view",
    ];

    this.fetchData();
    this._getSdomian();

    //判断权限
    console.log("初始化");
    if (this.userInfo && this.userInfo.is_admin == 0) {
      if (this.userInfo.permissions.length) {
        for (var i = 0; i < this.userInfo.permissions.length; i++) {
          console.log("进入循环");
          //机构管理
          if (
            canSeeAccountshow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.accountshow
          ) {
            this.power.accountshow = true;
          }
          //  机构计费中心
          if (
            canSeeAccountbillshow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.accountbillshow
          ) {
            this.power.accountbillshow = true;
          }
          //  机构订单管理
          if (
            canSeeAccountordershow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.accountordershow
          ) {
            this.power.accountordershow = true;
          }
          //  子网
          if (
            canSeeChildnetshow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.childnetshow
          ) {
            this.power.childnetshow = true;
          }
          //  日志
          if (
            canSeeLogshow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.logshow
          ) {
            this.power.logshow = true;
          }
          //  管理员
          if (
            canSeeManagershow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.managershow
          ) {
            this.power.managershow = true;
          }
          //  组织
          if (
            canSeeGroupshow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.groupshow
          ) {
            this.power.groupshow = true;
          }
          //  角色
          if (
            canSeeRoleshow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.roleshow
          ) {
            this.power.roleshow = true;
          }
          //  用户管理
          if (
            canCustomershow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.customershow
          ) {
            this.power.customershow = true;
          }
          //  s端服务--商户
          if (
            SportTenatshow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.SportTenatShow
          ) {
            this.power.SportTenatShow = true;
          }
          //S端服务--订单
          if (
            SportOrdershow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.SportOrderShow
          ) {
            this.power.SportOrderShow = true;
          }
          //S 端服务模板配置
          if (
            Sporttemplateshow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.SportTemplateShow
          ) {
            this.power.SportTemplateShow = true;
          }
          //热点池
          if (
            SportHotShow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.SportHotShow
          ) {
            this.power.SportHotShow = true;
          }
          // 消息推送配置
          if (
            pushSettingShow.includes(this.userInfo.permissions[i].real_name) &&
            !this.power.pushSettingShow
          ) {
            this.power.pushSettingShow = true;
            sessionStorage.setItem('pushSettingShow', true);
          }
          //  增值服务
          if (
            canSeeServiceordershow.includes(
              this.userInfo.permissions[i].real_name
            ) &&
            !this.power.serviceordershow
          ) {
            this.power.serviceordershow = true;
          }
        }
      }
    } else {
      console.log("超过进入打印域名", this.domain);
      this.power = {
        accountshow: true,
        accountbillshow: true,
        accountordershow: true,
        childnetshow: true,
        logshow: true,
        managershow: true,
        groupshow: true,
        roleshow: true,
        authshow: true,
        customershow: true,
        serviceordershow: true,
        userInfo: "",
      };
      if (this.domain == "p.review.slradio.cn") {
        this.power.SportTenatShow = true;
        this.power.SportOrderShow = true;
        this.power.SportTemplateShow = true;
        this.power.SportHotShow = true;
        this.power.pushSettingShow = true;
        sessionStorage.setItem('pushSettingShow', true);
      } else if (this.domain == "p.staging.slradio.cn") {
        this.power.SportTenatShow = true;
        this.power.SportOrderShow = true;
        this.power.SportTemplateShow = true;
        this.power.SportHotShow = true;
        this.power.pushSettingShow = true;
        sessionStorage.setItem('pushSettingShow', true);
      } else if (this.domain == "p.on-radio.cn") {
        console.log("333333333333333333333333");
        this.power.SportTenatShow = true;
        this.power.SportOrderShow = true;
        this.power.SportTemplateShow = true;
        this.power.SportHotShow = true;
        this.power.pushSettingShow = true;
        sessionStorage.setItem('pushSettingShow', true);
      } else {
        this.power.SportTenatShow = false;
        this.power.SportOrderShow = false;
        this.power.SportTemplateShow = false;
        this.power.SportHotShow = false;
        this.power.pushSettingShow = false;
        sessionStorage.setItem('pushSettingShow', false);
      }
    }
  },
  methods: {
    ...mapMutations("practice", ["setModules"]),
    fetchData() {
      let params = {
        status: 1,
        rows: 100,
        page: 1,
        platform_show: 1,
      };
      getNetwork(params)
        .then((res) => {
          this.subSystem = res.data.apps;
          //判断是否为云维
          for (let item of this.subSystem) {
            if (item.app === "cm") {
              this.ywData = item;
            }
            if (item.app === "cms") {
              localStorage.setItem('cmsApi',item.api_domain)
            }
          }
          console.log('yyyyyyyyyyyyyyyyyyy')
          // console.error("this.subSystem", this.subSystem);
          // console.log("获取的前端域名", this.subSystem);
          let antUrl = this.$route.query._f;
          if (antUrl && this.subSystem.length > 0) {
            let index = this.subSystem.findIndex((ele) => {
              return ele.app_platform_url == decodeURIComponent(antUrl);
            });
            if (index != -1) {
              localStorage.setItem(
                "systemUrl",
                this.subSystem[index].app_platform_url
              );
              localStorage.setItem(
                "subSystemName",
                this.subSystem[index].app_name
              );
              this.$refs.elMenu.open(3);
              this.subIndex = index;
              this.$router.push({
                path: "/subSystem/" + this.subSystem[index].id,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询文明实践增值状态
    _getModuleByParctice() {
      let list = [
        getModuleByParctice({ module: "uc_volunteer" }),
        getModuleByParctice({ module: "uc_credit" }),
      ];
      Promise.all(list).then((res) => {
        res.forEach((item) => {
          if (item.code === 100000) {
            if (item.data?.real_name === "uc_volunteer") {
              this.menu.practiceShow = this.judgeAppModlesStatus(item.data);
              this.setModules({ k: "practiceShow", v: this.menu.practiceShow });
            }
            if (item.data?.real_name === "uc_credit") {
              this.menu.creditShow = this.judgeAppModlesStatus(item.data);
              this.setModules({ k: "creditShow", v: this.menu.creditShow });
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },

    // 判断增值资源状态
    judgeAppModlesStatus(app) {
      if (app.status === 1) {
        // 有效
        if (app.expire === 1) {
          // 计算过期时间
          return new Date().getTime() < new Date(app.expire_at).getTime();
        } else if (app.count === 1) {
          // 计算次数
          return app.used_count < app.count_value;
        } else if (app.time === 1) {
          // 计算时长
          return app.used_time < app.time_value;
        } else {
          return false;
        }
      } else {
        // 无效
        return false;
      }
    },

    toSubSystem(index, item) {
      this.subIndex = index;
      if (item.app === "small" || (item.app.toLowerCase() == 'ccp')) {
        this.toApplicationAuth(item.app_platform_url);
        return;
      }
      if (item.app === "cp") {
        this.$router.push({ path: "/practice" });
        return;
      }
      if (item.app === "cp") {
        this.$router.push({ path: "/practice" });
        return;
      }
      if(item.app === "aigc-p"){
        this.handleOpenNewWindow(item.app_platform_url);
        return;
      }

      localStorage.setItem("systemUrl", this.subSystem[index].app_platform_url);
      localStorage.setItem("subSystemName", this.subSystem[index].app_name);
      this.$router.push({ path: "/subSystem/" + this.subSystem[index].id });
    },
    // 跳转需要带auth的应用
    toApplicationAuth(url) {
      // let authorization = getCookie("Authorization");
      let phone = JSON.parse(localStorage.getItem("userInfo")).user.phone;
      let path = `${url}?phone=${phone}&user=admin`;
      // let path = `http://127.0.0.1:8000/Admin/login?authorization=${authorization}&user=admin&phone=${phone}`;
      window.open(path);
      // window.location.href = path;
    },
    //打开新窗口
    handleOpenNewWindow(url){
      window.open(url);
    },
    // S端域名
    _getSdomian() {
      // getSdomian().then((res) => {
        // 拼接积分管理域名
        if (this.domias.mp_api_domain) {
          this.integralDomain =
            this.domias.mp_api_domain.replace(/api-mp/g, "api-i") +
            "/admin_p/credit";
        }
        console.log('this.integralDomain',this.integralDomain)
        localStorage.setItem("SportDomian", this.domias.mp_p_domain);
      // });
    },
    gotoSport() {
      this.MRADOM = Math.random();
    },
    toYw() {
      // window.open(this.ywData.platform_pc_domain);
      window.open(this.ywData.api_domain + "/admin/comprehensive");
    },
    // 运维S端
    toTecMag() {
      let url = localStorage.getItem('SportDomian');
      window.open(url + "/cm/cloud-index");
    },
    toIntegral() {
      window.open(this.integralDomain);
    },
  },
};
</script>
<style lang="scss">
// menu重置
.el-menu-item.is-active {
  color: #fff !important;
  background: #1890ff !important;
  .el-icon-arrow-down,
  .iconfontmenu,
  .iconfont {
    color: #fff !important;
  }
}
.el-menu-item:hover {
  color: #fff !important;
  // background-color: #1890ff !important;
  .el-icon-arrow-down,
  .iconfontmenu,
  .iconfont {
    color: #fff !important;
  }
}
.el-submenu__title .el-submenu__icon-arrow {
  color: #a3a7ab;
}
.el-submenu__title:hover {
  color: #fff !important;
  // background-color: #1890ff !important;
  .el-icon-arrow-down,
  .iconfontmenu,
  .iconfont {
    color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.el-menu {
  border-right: 0;
  color: #a3a7ab;
}
.menu-wrapper {
  height: -webkit-calc(100% - 64px);
  height: -moz-calc(100% - 64px);
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  .iconfontmenu,
  .iconfont {
    margin-right: 21px;
    font-size: 18px;
    color: #a3a7ab;
  }

  .iconfontmenu {
    margin-right: 21px;
  }

  .iconfontmenu {
    margin-right: 21px;
  }

  &::-webkit-input-placeholder {
    text-overflow: ellipsis;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    overflow: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333;
    min-height: 25px;
    min-width: 25px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: #222;
    border: 1px solid transparent;
  }
}
</style>
